import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from './logo.png';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const banks = [
  { name: 'BBVA', accountNumberLength: 18 },
  { name: 'BCP', accountNumberLength: 14 }
];
const documentTypes = ['DNI', 'Carné de extranjeria', 'Pasaporte'];

function App() {

  const [formData, setFormData] = useState({
    phoneNumber: '',
    verificationCode: '',
    names: '',
    bank: '',
    accountNumber: '',
    accountNumberint: '',
    documentType: '',
    documentNumber: '',
    licensePlate: '',
    procedure: '',
    additionalComments: '',
    payTo: '',
    thirdPartyDocumentType: '',
    thirdPartyDocumentNumber: '',
    thirdPartyNames: '',
    accountType: ''

  });
  const [errors, setErrors] = useState({});
  const [validations, setValidations] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isPhoneNumberFieldDisabled, setIsPhoneNumberFieldDisabled] = useState(false);
  const [isVerificationCodeButtonDisabled, setIsVerificationCodeButtonDisabled] = useState(true);
  const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(true);
  const [verificationCodeCountdown, setVerificationCodeCountdown] = useState(0);
  const [isCodeValidated, setIsCodeValidated] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false); // Estado para controlar el indicador de progreso
  const [idPartner, setIdPartner] = useState('');
  const [idYegor, setIdYegor] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [nombres, setNombres] = useState('');
  const [paymentOption, setPaymentOption] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Primera solicitud a la API
      const response = await fetch('https://api.yego.pe/api/Yango/RCuenta', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const data = await response.json();
        setModalMessage(data.message);
        setShowModal(true);

        if (data.estatus === 200) {

          // Limpia el formulario
          setFormData({
            names: '',
            phoneNumber: '',
            bank: '',
            accountNumber: '',
            accountNumberint: '',
            documentType: '',
            documentNumber: '',
            licensePlate: '',
            procedure: '',
            additionalComments: '',
            payTo: '',
            thirdPartyDocumentType: '',
            thirdPartyDocumentNumber: '',
            thirdPartyNames: '',
            accountType: ''
          });

          // Recarga la página después de un tiempo
          setTimeout(() => {
            setShowModal(false);
            window.location.reload();
          }, 3000);
        }
      } else {
        // Manejo de error en la primera solicitud
        const data = await response.json();
        setModalMessage(data.message);
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error de conexión:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'names') {
      if (/^[A-Za-z\s]*$/.test(value)) {
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: null });
        setValidations({ ...validations, [name]: true });
      } else {
        setErrors({ ...errors, [name]: 'Solo se permiten letras.' });
        setValidations({ ...validations, [name]: false });
      }
    } else if (name === 'phoneNumber') {
      if (value.match(/^[0-9]{0,9}$/)) {
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: null });
        setValidations({ ...validations, [name]: value.length === 9 });
      } else {
        setErrors({ ...errors, [name]: 'Solo debe contener 9 dígitos.' });
        setValidations({ ...validations, [name]: false });
      }
    } else if (name === 'accountNumber') {
      if (value.match(/^[0-9]*$/)) {
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: null });
        setValidations({ ...validations, [name]: value.length === (formData.bank ? banks.find(bank => bank.name === formData.bank).accountNumberLength : 0) });
      } else {
        setErrors({ ...errors, [name]: 'Solo debe contener dígitos' });
        setValidations({ ...validations, [name]: false });
      }
    } else if (name === 'accountNumberint') {
      if (value.match(/^[0-9]*$/)) {
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: null });
        setValidations({ ...validations, [name]: value.length === (formData.bank ? banks.find(bank => bank.name === formData.bank).accountNumberLength : 0) });
      } else {
        setErrors({ ...errors, [name]: 'Solo debe contener dígitos' });
        setValidations({ ...validations, [name]: false });
      }
    } else if (name === 'documentNumber') {
      if (formData.documentType === 'DNI') {
        if (value.match(/^[0-9]{0,8}$/)) {
          setFormData({ ...formData, [name]: value });
          setErrors({ ...errors, [name]: null });
          setValidations({ ...validations, [name]: value.length === 8 });
        } else {
          setErrors({ ...errors, [name]: 'Solo debe contener 8 dígitos' });
          setValidations({ ...validations, [name]: false });
        }
      } else if (formData.documentType === 'Carné de extranjeria') {
        if (value.match(/^[0-9]{0,12}$/)) {
          setFormData({ ...formData, [name]: value });
          setErrors({ ...errors, [name]: null });
          setValidations({ ...validations, [name]: value.length === 12 });
        } else {
          setErrors({ ...errors, [name]: 'Solo debe contener hasta 12 dígitos' });
          setValidations({ ...validations, [name]: false });
        }
      } else if (formData.documentType === 'Pasaporte') {
        if (value.match(/^[A-Za-z0-9]{0,12}$/)) {
          setFormData({ ...formData, [name]: value });
          setErrors({ ...errors, [name]: null });
          setValidations({ ...validations, [name]: value.length === 12 });
        } else {
          setErrors({ ...errors, [name]: 'Solo debe contener hasta 12 dígitos' });
          setValidations({ ...validations, [name]: false });
        }
      }
    } else if (name === 'licensePlate') {
      if (value.match(/^[A-Za-z0-9]{0,6}$/)) {
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: null });
        setValidations({ ...validations, [name]: value.length === 6 });
      } else {
        setErrors({ ...errors, [name]: 'Solo debe contener hasta 6 caracteres alfanuméricos' });
        setValidations({ ...validations, [name]: false });
      }
    } else if (name === 'additionalComments') {
      if (value.length <= 250) {
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: null });
      } else {
        setErrors({ ...errors, [name]: 'Solo se permite un máximo de 250 caracteres' });
      }
    } else if (name === 'bank') {
      const selectedBank = banks.find((bank) => bank.name === value);
      setFormData({ ...formData, [name]: value, accountNumber: '' });
      setErrors({ ...errors, accountNumber: null });
      setValidations({ ...validations, accountNumber: false });

      if (selectedBank) {
        const accountNumberLength = selectedBank.accountNumberLength;
        setValidations({ ...validations, accountNumber: formData.accountNumber.length === accountNumberLength });
      }
    } else if (name === 'payTo') {
      setFormData({ ...formData, [name]: value });
      setErrors({ ...errors, [name]: null });
    } else if (name === 'thirdPartyDocumentType') {
      setFormData({ ...formData, [name]: value });
      setErrors({ ...errors, [name]: null });
    } else if (name === 'thirdPartyDocumentNumber') {
      if (formData.thirdPartyDocumentType === 'DNI') {
        if (value.match(/^[0-9]{0,8}$/)) {
          setFormData({ ...formData, [name]: value });
          setErrors({ ...errors, [name]: null });
          setValidations({ ...validations, [name]: value.length === 8 });
        } else {
          setErrors({ ...errors, [name]: 'Solo debe contener 8 dígitos' });
          setValidations({ ...validations, [name]: false });
        }
      } else if (formData.thirdPartyDocumentType === 'Carné de extranjeria') {
        if (value.match(/^[0-9]{0,12}$/)) {
          setFormData({ ...formData, [name]: value });
          setErrors({ ...errors, [name]: null });
          setValidations({ ...validations, [name]: value.length === 12 });
        } else {
          setErrors({ ...errors, [name]: 'Solo debe contener hasta 12 dígitos' });
          setValidations({ ...validations, [name]: false });
        }
      } else if (formData.thirdPartyDocumentType === 'Pasaporte') {
        if (value.match(/^[A-Za-z0-9]{0,12}$/)) {
          setFormData({ ...formData, [name]: value });
          setErrors({ ...errors, [name]: null });
          setValidations({ ...validations, [name]: value.length === 12 });
        } else {
          setErrors({ ...errors, [name]: 'Solo debe contener hasta 12 dígitos' });
          setValidations({ ...validations, [name]: false });
        }
      }
    } else if (name === 'thirdPartyNames') {
      if (/^[A-Za-z\s]*$/.test(value)) {
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: null });
        setValidations({ ...validations, [name]: true });
      } else {
        setErrors({ ...errors, [name]: 'Solo se permiten letras.' });
        setValidations({ ...validations, [name]: false });
      }
    } else {
      setFormData({ ...formData, [name]: value });
      setErrors({ ...errors, [name]: null });
    }
  };

  const getRemainingCharacters = (maxLength, value) => {
    const remainingChars = maxLength - value.length;
    return remainingChars > 0 ? remainingChars : '';
  };

  const handleVerificationCodeButton = async () => {
    try {
      setIsSendingCode(true); // Mostrar el indicador de progreso

      const response = await fetch('https://api.yego.pe/api/Yango/getCode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phoneNumber: formData.phoneNumber,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.estatus === 400) {
          setModalMessage(data.message);
          setShowModal(true);
        } else {
          setIsVerificationCodeButtonDisabled(true);
          setIsPhoneNumberFieldDisabled(true);
          setVerificationCodeCountdown(60);

          const interval = setInterval(() => {
            setVerificationCodeCountdown((prevCountdown) => prevCountdown - 1);
          }, 1000);

          setTimeout(() => {
            clearInterval(interval);
          }, 60000);
        }
      } else {
        throw new Error('Error en la solicitud');
      }
    } catch (error) {
      console.error('Error de conexión:', error);
    } finally {
      setIsSendingCode(false); // Ocultar el indicador de progreso cuando se completa la solicitud
    }
  };

  const handleVerifyButton = async () => {
    try {
      const response = await fetch('https://api.yego.pe/api/Yango/sentCode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          phoneNumber: formData.phoneNumber,
          verificationCode: formData.verificationCode,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data)
        if (data.estatus === 200) {
          setIdPartner(data.idpartner);
          setIdYegor(data.idyegor);
          setIsCodeValidated(true);
          setApellidos(data.apellidos);
          setNombres(data.nombres);
          console.log(data.idpartner)
          console.log(data.idyegor)
        } else {
          setModalMessage(data.message);
          setShowModal(true);
        }
      } else {
        throw new Error('Error en la solicitud');
      }
    } catch (error) {
      console.error('Error de conexión:', error);
    }
  };

  useEffect(() => {
    setIsVerificationCodeButtonDisabled(formData.phoneNumber.length !== 9);
  }, [formData.phoneNumber]);

  useEffect(() => {
    setIsVerifyButtonDisabled(
      !formData.verificationCode || formData.verificationCode.length === 0 || formData.verificationCode.length > 6
    );
  }, [formData.verificationCode]);

  const handleChangePhoneNumber = (e) => {
    const phoneNumber = e.target.value.replace(/\D/g, ''); // Remover caracteres no numéricos
    setFormData({ ...formData, phoneNumber });
  };

  const handleChangeVerificationCode = (e) => {
    const verificationCode = e.target.value.replace(/\D/g, ''); // Remover caracteres no numéricos
    setFormData({ ...formData, verificationCode });
  };

  return (
    <div className="container">
      <div className="text-center mt-3">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <h2 className="text-center mb-4">Registro de Cuenta Bancaria</h2>
      <h4>Bienvenido, {nombres} {apellidos} </h4>
      <h6>
        Necesitamos saber a qué cuenta abonaremos tus ganancias, puede estar a tu nombre o de un tercero. <strong>A continuación SOLO REQUERIMOS LOS DATOS DEL TITULAR DE LA CUENTA DE FORMA MUY PRECISA SINO EL PAGO SERÁ RECHAZADO POR EL BANCO.</strong>
      </h6>
      <h6><strong>Recuerda:</strong><br></br>• Asegúrate de ingresar correctamente tu número de DNI o CE. (En el caso de extranjeros, usa el documento con el que registraste tu cuenta bancaria).<br></br>• Ingresa correctamente tu número de cuenta para pagos semanales (cuenta de ahorros) y el CCI de 20 dígitos para pagos a través de la aplicación. No olvides seleccionar el tipo de cuenta correctamente (cuenta corriente o cuenta de ahorros).<br></br>Si vas a registrar la cuenta bancaria de un tercero, asegúrate de incluir su nombre y DNI.</h6>

      <div className="mb-3">
        <br></br>
        <label className="form-label">Número de celular:</label>
        <div className="position-relative">
          <input
            type="tel"
            className="form-control"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChangePhoneNumber}
            maxLength={9}
            disabled={isPhoneNumberFieldDisabled || isCodeValidated}
          />
          <button
            className={`btn btn-primary position-absolute top-50 end-0 translate-middle-y pe-2 ${isCodeValidated ? 'green' : ''}`}
            disabled={isVerificationCodeButtonDisabled || isSendingCode}
            onClick={handleVerificationCodeButton}
          >
            {isSendingCode ? (
              <>
                <Spinner animation="border" size="sm" role="status" /> Enviando...
              </>
            ) : isCodeValidated ? (
              'Validado'
            ) : (
              'Solicitar código'
            )}
          </button>
        </div>
      </div>

      {isCodeValidated && <p className="text-success">Teléfono verificado</p>}

      {isCodeValidated && (
        <form onSubmit={handleSubmit}>
          {/* Selector de opciones de pago */}
          <div className="mb-3">
            <label className="form-label">Selecciona una opción de pago:</label>
            <select
              className="form-select"
              value={paymentOption}
              onChange={(e) => setPaymentOption(e.target.value)}
            >
              <option value="">Selecciona una opción</option>
              <option value="otros-bancos">SOLO PARA PAGOS EN CUALQUIER OTRO BANCO (EXCEPTO BCP Y BBVA)</option>
              <option value="bcp-bbva">SOLO PARA PAGOS DE BANCOS BCP Y BBVA</option>
            </select>
          </div>

          {/* Formulario para PAGOS DE CUALQUIER BANCO (EXCEPTO BCP Y BBVA) */}
          {paymentOption === 'otros-bancos' && (
            <div>
              <div className="mb-3">
                <h4><strong>PAGOS DE CUALQUIER BANCO (EXCEPTO BCP Y BBVA)</strong></h4>
                <h6>Ingresar Código de Cuenta Interbancario (CCI)  de cualquier banco - Se realizará el cobro de la comisión por transferencia interbancaria, el costo de esta es de S/ 4.30 en Lima y S/ 10.50 en Provincia.</h6>
              </div>
              <div className="mb-3">
                <label className="form-label">Tipo de Documento del titular de la cuenta:</label>
                <select
                  className="form-select"
                  name="documentType"
                  value={formData.documentType}
                  onChange={handleChange}
                >
                  <option value="">Selecciona un tipo de documento</option>
                  {documentTypes.map((type) => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
              {formData.documentType && (
                <div className="mb-3">
                  <label className="form-label">Número de documento del titular de la cuenta:</label>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      name="documentNumber"
                      value={formData.documentNumber}
                      onChange={handleChange}
                      maxLength={formData.documentType === 'DNI' ? 8 : 12}
                    />
                  </div>
                </div>
              )}
              <div className="mb-3">
                <label className="form-label">Nombres y Apellidos del titular de la cuenta:</label>
                <input
                  type="text"
                  className="form-control"
                  name="names"
                  value={formData.names}
                  onChange={handleChange}
                />
              </div>
              <label className="form-label">Tipo de cuenta:</label>
              <div className="mb-3">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="accountType"
                    value="Cuenta de ahorros"
                    checked={formData.accountType === 'Cuenta de ahorros'}
                    onChange={handleChange}
                  />
                  <label className="form-check-label">Cuenta de ahorros</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="accountType"
                    value="Cuenta corriente"
                    checked={formData.accountType === 'Cuenta corriente'}
                    onChange={handleChange}
                  />
                  <label className="form-check-label">Cuenta corriente</label>
                </div>
              </div>
              <label className="form-label">CCI de la cuenta:</label>
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  name="accountNumberint"
                  value={formData.accountNumberint}
                  onChange={handleChange}
                  maxLength="20"
                />
              </div>
            </div>
          )}

          {/* Formulario para PAGOS DE BANCOS BCP Y BBVA */}
          {paymentOption === 'bcp-bbva' && (
            <div>
              <div className="mb-3">
                <h4><strong>PAGOS DE BANCOS BCP Y BBVA</strong></h4>
                <h6>Yego hace un corte de tu saldo positivo (estado financiero) todos los jueves en la mañana y lo abonamos a la cuenta que registres todos los jueves en la tarde. Se realizará el cobro de la transacción, el costo de esta es de solo S/ 1 (un sol) por parte del proveedor de pago.</h6>
              </div>

              <div className="mb-3">
                <label className="form-label">Tipo de Documento del titular de la cuenta:</label>
                <select
                  className="form-select"
                  name="documentType"
                  value={formData.documentType}
                  onChange={handleChange}
                >
                  <option value="">Selecciona un tipo de documento</option>
                  {documentTypes.map((type) => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
              {formData.documentType && (
                <div className="mb-3">
                  <label className="form-label">Número de documento del titular de la cuenta:</label>
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      name="documentNumber"
                      value={formData.documentNumber}
                      onChange={handleChange}
                      maxLength={formData.documentType === 'DNI' ? 8 : 12}
                    />
                  </div>
                </div>
              )}
              <div className="mb-3">
                <label className="form-label">Nombres y Apellidos del titular de la cuenta:</label>
                <input
                  type="text"
                  className="form-control"
                  name="names"
                  value={formData.names}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Banco:</label>
                <select
                  className="form-select"
                  name="bank"
                  value={formData.bank}
                  onChange={handleChange}
                >
                  <option value="">Selecciona un banco</option>
                  {banks.map((bank) => (
                    <option key={bank.name} value={bank.name}>{bank.name}</option>
                  ))}
                </select>
              </div>
              {formData.bank && (
                <div>
                  <div className="mb-3">
                    <label className="form-label">Numero de cuenta bancaria:</label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control"
                        name="accountNumber"
                        value={formData.accountNumber}
                        onChange={handleChange}
                        maxLength={banks.find((bank) => bank.name === formData.bank)?.accountNumberLength}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Mostrar botón "Enviar" solo cuando se elige una opción */}
          {paymentOption && (
            <div className="text-center mt-4">
              <button type="submit" className="btn btn-primary">Enviar</button>
            </div>
          )}
        </form>
      )}

      {verificationCodeCountdown > 0 && !isCodeValidated && (
        <p className="text-center">
          Volver a solicitar código de validación en {verificationCodeCountdown} segundos.
        </p>
      )}

      {verificationCodeCountdown > 0 && !isCodeValidated && (
        <div className="mb-3">
          <label className="form-label">Ingrese código de validación:</label>
          <div className="position-relative d-flex">
            <input
              type="text"
              className="form-control"
              name="verificationCode"
              value={formData.verificationCode}
              onChange={handleChangeVerificationCode}
              maxLength={6}
              pattern="\d*"
              inputMode="numeric"
              disabled={isCodeValidated}
              style={{ marginRight: '10px' }}
            />
            <button
              className="btn btn-primary"
              disabled={isVerifyButtonDisabled || isCodeValidated}
              onClick={handleVerifyButton}
              style={{ marginLeft: '10px' }}
            >
              Verificar
            </button>
          </div>
        </div>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Yego Mensaje</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

}

export default App;